<template>
  <dialog-outline v-model="showDialog" max-width="100%" :visible-header="false">
    <template v-slot:context>
      <!--
        IMPORTANT: with in document-viewer,
        make sure to load and display ONLY when  is-open-document = true!
      -->
      <document-viewer
        v-if="showDialog"
        :record-id="id"
        :record-version="version"
        :show-close-button="true"
        :is-open-document="showDialog"
        :is-scan-action-request="openScanDialog"
        @update-record="updateRecord"
        @close="closeDialog()"
      >
      </document-viewer>
    </template>
  </dialog-outline>
</template>

<script>
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "DocumentEditorDialog",
  mixins: [dialogOutlineMixin],
  inheritAttrs: false,
  components: {
    DocumentViewer: () => import("@/views/document/DocumentViewer")
  },
  props: {
    /**
     * Record id
     */
    id: {
      type: Number,
      default: -1
    },
    /**
     * Record-File Version
     */
    version: {
      type: Number,
      default: 0
    },
    openScanDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateRecord(record) {
      this.$emit(eventNames.updateRecord, record);
    }
  }
};
</script>
